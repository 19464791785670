import React, { useRef, useState, Fragment } from 'react';


import '../Style.css';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
} from "react-router-dom";


import logo from '../images/logo-slogan.png'
import logo2 from '../images/logo.png'

import { Overlay, Button } from 'react-bootstrap';

import { ImMail } from "react-icons/im";
import { FaSquarePhone } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaFacebookSquare } from "react-icons/fa";
import { FaSkype } from "react-icons/fa";

import Careerform from '../Pages/career';
import $ from "jquery";


function Footer() {

    const [showPopover, setShowPopover] = useState(false);
    const target = useRef(null);


    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    const handleNavClick = () => {
        const collapseElement = document.getElementById('navbarSupportedContent');
        if (collapseElement.classList.contains('show')) {
            collapseElement.classList.remove('show'); // Toggle collapse state
        }
    };




    return (
        <div>

            {/* =============================FOOTER-SECTION================================= */}
            < section id='footer_section' className='mt-5' >
                <div className='container'>
                    <div className='row row-cols-1 footer-container  justify-content-center align-items-center text-center'>
                        <div className='col-lg-8'>
                            <div>
                                <img className='footer-logo-sizing' src={logo} alt='logo' />
                            </div>
                            <div className='mt-3 d-flex flex-wrap flex-row justify-content-evenly g-4 text-center'>
                                <div className='px-2'>
                                    <Link className="footer-small-heading-size-css mb-0 fc-1" to="/" onClick={() => { scrollToSection('bannersectionHome'); handleNavClick(); }}>Home</Link>
                                </div>
                                <div className='px-2'>
                                    <Link className="footer-small-heading-size-css mb-0 fc-1" to="/" onClick={() => { scrollToSection('aboutsection'); handleNavClick(); }}>About us</Link>
                                </div>
                                <div className='px-2'>
                                    <Link className="footer-small-heading-size-css mb-0 fc-1" to="/" onClick={() => { scrollToSection('services_section'); handleNavClick(); }}>Services</Link>
                                </div>
                                {/* <div className='px-2'>
                                    <Careerform />
                                </div> */}
                                <div className='px-2'>
                                    <a href='/privacypolicy' target='_blank' className='footer-small-heading-size-css mb-0 fc-1'>Privacy Policy</a>
                                </div>
                                <div className='px-2'>
                                    <a href='/termsandconditions' target='_blank' className='footer-small-heading-size-css mb-0 fc-1'>Terms and conditions</a>
                                </div>

                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='mt-3 row row-cols-lg-4 row-cols-4 justify-content-center g-4 text-center'>
                                <div className='col'>
                                    <a href="mailto:support@leaturetech.com" target='_blank'>
                                        <ImMail className='footer-icon-size-css mb-0 fc-1' />
                                    </a>

                                    {/* <a href="mailto:support@leaturetech.com?subject=Job Application">
                                        <ImMail className='footer-icon-size-css mb-0 fc-1' />
                                    </a> */}


                                </div>
                                <div className='col'>
                                    <a className='d-block d-lg-none' href="tel:+91 7904807176" >
                                        <FaSquarePhone className='footer-icon-size-css mb-0 fc-1' />

                                    </a>
                                    {/* Using Overlay component for popover */}
                                    <Overlay

                                        show={showPopover}
                                        target={target.current}
                                        placement="top"
                                    >
                                        {/* Content of the popover */}
                                        {(props) => (
                                            <div
                                                {...props}
                                                style={{
                                                    backgroundColor: '#ffbe58',
                                                    color: '#000000',
                                                    padding: '0.5em 0.5em',
                                                    margin: '0.6em',
                                                    fontSize: '20px', // Adjust font size as needed
                                                    fontWeight: '600', // Adjust font weight as needed
                                                    fontFamily: 'Work Sans, sans-serif', // Adjust font family as needed
                                                    borderRadius: '0.5em 0.5em 0.5em 0.5em',
                                                    border: '3px double #25252586', // Dotted border

                                                    ...props.style,
                                                }}
                                            >
                                                +91 7904807176
                                            </div>
                                        )}
                                    </Overlay>

                                    {/* Button to toggle popover */}
                                    <a className="d-none d-lg-block"
                                        ref={target}
                                        onClick={() => setShowPopover(!showPopover)}
                                    >
                                        <FaSquarePhone className='footer-icon-size-css mb-0 fc-1' />

                                    </a>


                                </div>
                                <div className='col'>
                                    <a href='https://www.linkedin.com/company/leature-technologies' target='_blank'>
                                        <FaLinkedin className='footer-icon-size-css mb-0 fc-1' />

                                    </a>
                                </div>
                                {/* <div className='col'>
                                    <a href="mailto:career@leaturetech.com">
                                        <FaInstagramSquare className='footer-icon-size-css mb-0 fc-1' />

                                    </a>
                                </div> */}
                                <div className='col'>
                                    <a href='skype:live:.cid.e55bfede8a2ac35c?chat' target='_blank'>
                                        <FaSkype className='footer-icon-size-css mb-0 fc-1' />

                                    </a>
                                </div>
                                {/* <div className='col'>
                                    <a href=''>
                                        <FaFacebookSquare className='footer-icon-size-css mb-0 fc-1' />

                                    </a>
                                </div> */}

                            </div>
                        </div>
                        <div className='col'>
                            <p className='footer-small-heading-size-css my-5 '>Copyright © 2024. Leature Technologies. All Rights Reserved.</p>
                        </div>


                    </div>
                </div>
            </section >



        </div >

    );
}

export default Footer;
