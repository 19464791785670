import React from "react";
import './Style.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import $ from "jquery";

import Mainbanner from './Mainbanner.js';
import Navbar from './Common/Navbar.js';
import Footer from './Common/Footer.js';
import Privacypolicy from './Pages/Privacypolicy.js';
import Termsandconditions from './Pages//Termsandconditions.js';

function App() {
  return (
    <div>
      <Router >

        <Routes>
          <Route exact path="/" element={<Mainbanner />} />
          <Route exact path="/navbar" element={<Navbar />} />
          <Route exact path="/footer" element={<Footer />} />
          <Route exact path="/privacypolicy" element={<Privacypolicy />} />
          <Route exact path="/termsandconditions" element={<Termsandconditions />} />



        </Routes>

      </Router >
    </div>

  );
}

export default App;
