import React, { useRef, useState, Fragment } from 'react';
import Navbar from '../Common/Navbar.js';
import Footer from '../Common/Footer.js';


import '../Style.css';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
} from "react-router-dom";


import $ from "jquery";


function Privacypolicy() {


    return (
        <div>
            <Navbar />
            {/* =============================Privacy-Policy-SECTION================================= */}

            <section>
                <div className='container pt-5'>
                    <div className='row  min-vh-100 justify-content-center align-items-center pt-5 '>
                        <div className='col-lg-11'>
                            <p className='big-heading-size-css py-4 text-center'>Privacy Policy  <span className='fc-1'></span></p>
                            <div className='row'>
                                <div className='col'>
                                    <p className='small-heading-size-css mb-0 fc-1'>Protecting Your Privacy
                                    </p>
                                    <p className='inner-para-styling-css'>At Leature Technologies, we take your privacy seriously. This Privacy Policy outlines the types of information we collect, how we use it, and the choices you have about your information. We are committed to being transparent about our data practices and to protecting your privacy.
                                    </p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col'>
                                    <p className='small-heading-size-css mb-0 fc-1'>
                                        Information We Collect
                                    </p>
                                    <p className='inner-para-styling-css'>
                                        We collect information to provide you with the best possible experience and to deliver the services you expect from Leature. This information can be categorized as follows:
                                    </p>
                                    <div>
                                        <p className='card-inner-title-css'>Information You Provide :</p>
                                        <p className='border-bottom-css-2'></p>
                                        <p className='inner-para-styling-css'>
                                            When you contact us through our website <span className='fc-1'>www.leaturetech.com</span> or email us at <span className='fc-1'>business@leaturetech.com,</span> you may provide us with your name, email address, phone number, and project details.
                                        </p>
                                    </div>
                                    <div>
                                        <p className='card-inner-title-css'>Usage Data :</p>
                                        <p className='border-bottom-css-2'></p>
                                        <p className='inner-para-styling-css'>
                                            We may collect information about how you use our website, such as the pages you visit and the duration of your visit. This information is used to improve the user experience and website functionality.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col'>
                                    <p className='small-heading-size-css mb-0 fc-1'>
                                        How We Use Your Information
                                    </p>

                                    <div>
                                        <p className='card-inner-title-css'>We use the information we collect for the following purposes :
                                        </p>
                                        <p className='border-bottom-css-2'></p>
                                        <p className='inner-para-styling-css'>
                                            1. To respond to your inquiries and provide you with the services you request.
                                        </p>
                                        <p className='inner-para-styling-css'>
                                            2. To improve our website and services.
                                        </p>
                                        <p className='inner-para-styling-css'>
                                            3. To send you information about Leature, including updates, promotions, and other relevant content (with your consent).
                                        </p>
                                    </div>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col'>
                                    <p className='card-inner-title-css'>We will never sell or share your personal information with third parties without your consent.
                                    </p>
                                    <div>
                                        <p className='small-heading-size-css mb-0 fc-1'>
                                            Cookies and Tracking Technologies
                                        </p>
                                        <p className='inner-para-styling-css'>
                                            We may use cookies and other tracking technologies to collect information about your website usage. Cookies are small data files that are stored on your device. You can control how cookies are used by adjusting your browser settings. Disabling cookies may limit your ability to use certain features of our website.
                                        </p>
                                    </div>

                                </div>
                            </div>

                            <div className='row'>
                                <div className='col'>
                                    <p className='card-inner-title-css'>Your Choices.
                                    </p>
                                    <div>
                                        <p className='small-heading-size-css mb-0 fc-1'>
                                            You have choices regarding your information:
                                        </p>
                                    </div>
                                    <div>
                                        <p className='card-inner-title-css'>Accessing and Updating Your Information :
                                        </p>
                                        <p className='border-bottom-css-2'></p>
                                        <p className='inner-para-styling-css'>
                                            You can request access to your personal information and request that we update it.
                                        </p>
                                    </div>
                                    <div>
                                        <p className='card-inner-title-css'>Unsubscribing from Communications :
                                        </p>
                                        <p className='border-bottom-css-2'></p>
                                        <p className='inner-para-styling-css'>
                                            You can unsubscribe from our marketing communications at any time.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col'>
                                    <p className='small-heading-size-css mb-0 fc-1'>Data Security
                                    </p>
                                    <div>
                                        <p className='inner-para-styling-css'>
                                            We take steps to protect your information from unauthorized access, disclosure, alteration, or destruction. However, no website or internet transmission is completely secure.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col'>
                                    <p className='small-heading-size-css mb-0 fc-1'>Changes to this Privacy Policy
                                    </p>
                                    <div>
                                        <p className='inner-para-styling-css'>
                                            We may update this Privacy Policy from time to time. We will notify you of any changes by posting the updated Privacy Policy on our website.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col'>
                                    <p className='small-heading-size-css mb-0 fc-1'>Contact Us
                                    </p>
                                    <div>
                                        <p className='inner-para-styling-css'>
                                            If you have any questions about this Privacy Policy, please contact us at <span className='fc-1'>admin@leaturetech.com,</span>.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className=''>
                                <p className='footer-small-heading-size-css text-center '>This Privacy Policy is effective as of April 09, 2024.
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </section >

            <Footer />
        </div >

    );
}

export default Privacypolicy;
