import React, { useRef, useState, Fragment } from 'react';
import { Link } from "react-router-dom";

import logo from '../images/logo-slogan.png';
import logo2 from '../images/logo.png';

import { ImMail } from "react-icons/im";
import { FaSquarePhone } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaTwitterSquare } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";

import Careerform from '../Pages/career';
function Navbar() {

    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    const handleNavClick = () => {
        const collapseElement = document.getElementById('navbarSupportedContent');
        if (collapseElement.classList.contains('show')) {
            collapseElement.classList.remove('show'); // Toggle collapse state
        }
    };

    return (
        <div>
            {/* =============================NAVBAR-SECTION================================= */}

            <div id='navsection'>
                <nav id='navbarColor' className="navbar navbar-expand-lg ">
                    <div className="container-fluid gx-0 gx-lg-4 ">
                        <a className="navbar-brand ps-3" href="/">
                            <img src={logo2} className='nav-logo-css' alt="Company Logo" />
                        </a>
                        <button className="navbar-toggler me-4" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse " id="navbarSupportedContent">
                            <ul className="navbar-nav mx-auto mb-2 mb-lg-0 px-4">
                                <li className="nav-item">
                                    <Link className="nav-link nav-text-css" to="/" onClick={() => { scrollToSection('bannersectionHome'); handleNavClick(); }}>Home</Link>

                                </li>
                                <li className="nav-item ">
                                    <Link className="nav-link nav-text-css" to="/" onClick={() => { scrollToSection('aboutsection'); handleNavClick(); }}>About us</Link>
                                </li>
                                <li className="nav-item ">
                                    <Link className="nav-link nav-text-css" to="/" onClick={() => { scrollToSection('services_section'); handleNavClick(); }}>Services</Link>
                                </li>
                                <li className="nav-item ">
                                    <Careerform />
                                </li>
                                <li className="nav-item ">
                                    <Link className="nav-link nav-text-css" to="/" onClick={() => { scrollToSection('footer_section'); handleNavClick(); }}>Contact us</Link>
                                </li>

                            </ul>

                        </div>
                    </div>
                </nav>
            </div>


        </div>
    );
}

export default Navbar;
