import React, { useRef, useState, Fragment } from 'react';

import Navbar from './Common/Navbar.js';
import Footer from './Common/Footer.js';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';

import 'swiper/css/effect-fade';

// import required modules
import { Autoplay, EffectFade } from 'swiper/modules';


import './Style.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
} from "react-router-dom";

import ceo from './images/Teams/CEO.jpg'
import coo from './images/Teams/COO.jpg'
import cbo from './images/Teams/CBO.jpg'
import cmo from './images/Teams/CMO.jpg'


import medical from './images/Industries/Medical.png'
import automobile from './images/Industries/Automobile.png'
import beauty from './images/Industries/beauty.png'
import booking from './images/Industries/booking.png'

import logo from './images/logo-slogan.png'
import logo2 from './images/logo.png'

import { FaLaptopCode } from "react-icons/fa";
import { TbDeviceMobileCode } from "react-icons/tb";
import { TbDeviceDesktopStar } from "react-icons/tb";
import { MdGroups } from "react-icons/md";

import { RiReactjsLine } from "react-icons/ri";
import { FaHtml5 } from "react-icons/fa";
import { SiCss3 } from "react-icons/si";
import { SiJquery } from "react-icons/si";
import { IoLogoJavascript } from "react-icons/io5";
import { FaNodeJs } from "react-icons/fa";
import { SiFlutter } from "react-icons/si";
import { FaBootstrap } from "react-icons/fa";
import { FaAngular } from "react-icons/fa";
import { FaPython } from "react-icons/fa";
import { SiFirebase } from "react-icons/si";
import { TbBrandMysql } from "react-icons/tb";
import { SiWebflow } from "react-icons/si";
import { FaAws } from "react-icons/fa6";
import { BsGithub } from "react-icons/bs";
import { TbBrandAzure } from "react-icons/tb";
import { FaJava } from "react-icons/fa";


import angular from './images/Technologies/angular.png';
import boostrap from './images/Technologies/boostrap.png';
import css3 from './images/Technologies/css3.png';
import firebase from './images/Technologies/firebase.png';
import flutter from './images/Technologies/flutter.png';
import html5 from './images/Technologies/html5.png';
import jquery from './images/Technologies/jquery.png';
import mysql1 from './images/Technologies/mysql1.png';
import nodejs from './images/Technologies/nodejs.png';
import python from './images/Technologies/python.png';
import reactjs from './images/Technologies/reactjs.png';
import reactnative from './images/Technologies/reactjs.png';
import AWS from './images/Technologies/AWS.png';
import Azure from './images/Technologies/Azure.png';
import GitHub from './images/Technologies/GitHub.png';
import Mendixcolor from './images/Technologies/Mendix-color.png';
import Mendix from './images/Technologies/Mendix.png';
import Outsystemscolor from './images/Technologies/Outsystems-color.png';
import Outsystems from './images/Technologies/Outsystems.png';
import Webflow from './images/Technologies/Webflow.png';
import java from './images/Technologies/java.png';
import mongodb from './images/Technologies/mongodb.png';
import mongodbcolor from './images/Technologies/mongodb-color.png';
import php from './images/Technologies/php.png';
import phpcolor from './images/Technologies/php-color.png';
import zohocolor from './images/Technologies/zoho-color.png';
import zoho from './images/Technologies/zoho.png';
import django from './images/Technologies/Django.png';
import djangocolor from './images/Technologies/Django-color.png';

import { TbPlayerTrackPrevFilled } from "react-icons/tb";
import { TbPlayerTrackNextFilled } from "react-icons/tb";

import { ImMail } from "react-icons/im";
import { FaSquarePhone } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaTwitterSquare } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";

import $ from "jquery";

import LazyLoad from 'react-lazyload';

const MyImage = ({ src, alt, ...props }) => {
  return (
    <LazyLoad placeholder={<div>Loading...</div>}>
      <img src={src} alt={alt} {...props} />
    </LazyLoad>
  );
};


const cards = [
  {
    frontTitle: <Fragment>Web<br />Development</Fragment>,
    frontIcon: <FaLaptopCode className='card-icon-styling-css' />,
    backContent: (
      <p className='card-inner-para-css '>
        Your website should be an extension of your brand, not just a virtual address. Leature works closely with you to understand your vision and target audience, crafting a website that delivers a seamless and engaging user experience.
      </p>
    ),
  },
  {
    frontTitle: <Fragment>Mobile App<br />Development</Fragment>,
    frontIcon: <TbDeviceMobileCode className='card-icon-styling-css' />,
    backContent: (
      <p className='card-inner-para-css '>
        The Mobile revolution is here. Leature's experienced app development team creates powerful mobile applications that not only meet user needs but also drive business growth and brand engagement.
      </p>
    ),
  },
  {
    frontTitle: <Fragment>Website Revamp<br />Maintenance</Fragment>,
    frontIcon: <TbDeviceMobileCode className='card-icon-styling-css' />,
    backContent: (
      <p className='card-inner-para-css '>
        We don't just build websites, we build partnerships! Leature goes the extra mile with website revamps and ongoing maintenance. We'll keep your online presence looking fresh and working hard, so you can focus on running your business.
      </p>
    ),
  },
  {
    frontTitle: <Fragment>Providing a<br />Dedicated Team</Fragment>,
    frontIcon: <TbDeviceMobileCode className='card-icon-styling-css' />,
    backContent: (
      <p className='card-inner-para-css '>
        Building an internal team? More like building a budget black hole. Leature's dedicated development solution gives you access to expert skills without the added stress of recruitment, training, and benefits. It's a win-win!
      </p>
    ),
  },
];

const Card = ({ frontTitle, frontIcon, backContent, isFlipped, onClick }) => {
  return (
    <div className="col column-align-css">
      <div className='flip-card'>
        <div className='card zig-zag-card-css h-100'>
          <div className={`flip-card-inner ${isFlipped ? 'flipped' : ''}`}>
            <div className="flip-card-front text-center">
              <div className='text-center pb-3'>
                {frontIcon}
              </div>
              <p className='card-inner-title-css text-center mb-4'>{frontTitle}</p>
              <button className="btn flip-button-css" onClick={onClick}>
                View more
              </button>
            </div>
            <div className="flip-card-back text-center">
              {backContent}
              <button className="btn flip-button-css" onClick={onClick}>
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


function Mainbanner() {
  $(function () {
    // Hide icons by default
    $('.tech-icon-styling-css').hide();

    // Function to animate a card column with optional looping
    function animateCardColumn(columnIndex, loop = true) {
      const card = $('.col').eq(columnIndex).find('.tech-card-css');

      // Hide image, show icon (smooth fade-in effect)
      card.find('.tech-img-styling-css').hide();
      card.find('.tech-icon-styling-css').show(900);

      setTimeout(function () {
        card.find('.tech-img-styling-css').show();
        card.find('.tech-icon-styling-css').hide(900);

        // Adjust next column index based on loop behavior
        const nextColumnIndex = loop ? (columnIndex + 1) % $('.col').length : 0;

        // Animate the next column after a delay
        animateCardColumn(nextColumnIndex, loop);
      }, 3000);
    }

    // Start the animation from the first column, optionally with looping
    animateCardColumn(0, true); // Looping enabled
    // animateCardColumn(0, false); // Looping disabled (one-time animation)
  });


  // const [allFlipped, setAllFlipped] = useState(false);

  // const handleAllFlip = () => {
  //   setAllFlipped(!allFlipped);
  // };

  const [flippedIndex, setFlippedIndex] = useState(null);

  const handleCardClick = (index) => {
    setFlippedIndex(index === flippedIndex ? null : index);
  };


  $(document).ready(function () {
    $('.white-card').mouseenter(function () {
      $(this).siblings('.orange-border').removeClass('shrink').addClass('grow');
    });

    $('.white-card').mouseleave(function () {
      $(this).siblings('.orange-border').removeClass('grow').addClass('shrink');
    });

  });

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
      {/* =============================NAVBAR-SECTION================================= */}

      <Navbar />

      {/* =============================BANNER-SECTION========================================= */}


      <section id='bannersectionHome' className='py-lg-5'>
        <div className='container banner-two-bg-css '>
          <div className=" row row-cols-lg-1 row-cols-1 justify-content-center align-items-center   min-vh-100 text-center">

            <div className="col-lg-6  ">
              <p className='big-heading-size-css mb-2'>Craft your <span className='fc-1'>vision</span><br />Launch it <span className='fc-1'>flawlessly</span></p>
              <p className='small-heading-size-css'>Your development partner, every step of the way.</p>
              <div className='d-flex justify-content-center align-items-center text-center pt-3'>
                <Link className="" to="/" onClick={() => scrollToSection('industries_section')}>
                  <div className='button-container '>
                    <button class="button ">Explore</button>
                    <div class="button__bottom"></div>
                    <div class="button__shadow"></div>
                  </div>
                </Link>
              </div>
            </div>

          </div>
        </div>

        <svg width="0" height="0">
          <defs>
            <clipPath id="myCurve" clipPathUnits="objectBoundingBox">
              <path
                d="M 0,1
									L 0,0
									L 1,0
									L 1,1
									C .65 .8, .35 .8, 0 1
									Z"
              />
            </clipPath>
          </defs>
        </svg>
        <svg width="0" height="0">
          <defs>
            <clipPath id="myCurvetwo" clipPathUnits="objectBoundingBox">
              <path
                d="M 0,1
           L 0,0
           L 1,0
           L 1,1
           C .63 .9, .33 .9, 0 1
           Z"
              />
            </clipPath>
          </defs>
        </svg>

      </section >

      {/* =============================ABOUT-US-SECTION========================================= */}
      < section id='aboutsection' className='aboutus-section pt-lg-5 pt-5'  >
        <div className='container'>
          <div className='row min-vh-lg-100 row-cols-lg-1 row-cols-1 py-5 py-lg-5 align-items-center justify-content-center g-4 '>

            <div className='col-lg-9 text-center'>
              <p className='small-heading-size-css mb-0 fc-1'>About Us</p>
              <p className='big-heading-size-css mb-2'>Cultivating the <span className='fc-1'>Future </span> <br />Pixel by Pixel</p>
              <div className='row  justify-content-center'>
                <p className='border-bottom-css'></p>
              </div>
              <p className='inner-para-styling-css'>We're Leature, Where Digital Experiences Take Root. We're Code Wizards, Not Robots. But We Still Write Great Code. We're Leature, a bunch of tech enthusiasts obsessed with creating awesome web and app experiences. We're not your typical suit-and-tie developers although some of us do rock a killer pair of sneakers.
              </p>
              <p className='inner-para-styling-css'>
                Think of us as your digital dream team. We bring your ideas to life, pixel by pixel, with a sprinkle of creativity and a whole lot of caffeine. We don't just write code, we craft stories – stories that make your brand shine and leave users saying "wow!"
              </p>
              <p className='inner-para-styling-css'>
                Ready to grow something amazing together?
                Let's chat about your project! We'd love to hear your ideas and see how we can turn them into a digital masterpiece.
              </p>
              <a className="" href="mailto:business@leaturetech.com" target='_blank'>
                <button className="btn flip-button-css" >
                  Let's chat
                </button>
              </a>
            </div>
          </div>
        </div>
      </section >


      {/* =============================SECTION-2========================================= */}

      <section id='services_section' className='services-bg-styling'>
        <div className='container py-5'>
          <div className='row row-cols-lg-2 row-cols-1 min-vh-100 align-items-center justify-content-center g-4'>
            <div className='col-lg-5 '>
              <p className='small-heading-size-css mb-0 fc-1'>Our Services</p>
              <p className='big-heading-size-css mb-2'>What We <span className='fc-1'>Do</span></p>
              <p className='border-bottom-css'></p>
              <p className='inner-para-styling-css'>Website feeling a bit stale? App idea stuck in the mud? Leature can breathe new life into your projects, or help you build something amazing from scratch.
              </p>
              <p className='inner-para-styling-css'>
                We're all about making your digital life easier, and that means offering revamp services, top-notch development, and ongoing maintenance to keep everything running smoothly.
              </p>
            </div>
            <div className='col-lg-7 '>
              <div className='row card-container-columns zig-zag-row-height row-cols-lg-2 row-cols-1 justify-content-center g-4'>
                {cards.map((card, index) => (
                  <Card
                    key={index}
                    {...card}
                    isFlipped={index === flippedIndex}
                    onClick={() => handleCardClick(index)}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* =============================TECHNOLOGIES-SECTION================================= */}

      <section id='tech_section' className='tech-section-css'>
        <div className='container'>
          <div className='text-center  tech-title-postion'>
            <p className='small-heading-size-css mb-0 fc-1'>Technologies</p>
            <p className='big-heading-size-css mb-2 '>What We <span className='fc-1'>Use</span></p>
            <div className='row  justify-content-center '>
              <p className='border-bottom-css'></p>
            </div>
          </div>
          <div className='row min-vh-100'>
            <div class="tab-content" id="pills-tabContent">
              <div class="tab-pane fade show active" id="pills-tech-1" role="tabpanel" aria-labelledby="pills-tech-1-tab" tabindex="0">
                <div>
                  <div className='row row-cols-lg-6 row-cols-3 tech-row-height-css justify-content-between  '>
                    <div className='col align-self-lg-center'>
                      <div className='card tech-card-css text-center align-items-center'>
                        <div>
                          <img className='tech-img-styling-css  ' src={reactjs} />
                          <RiReactjsLine className='tech-icon-styling-css fc-react ' />
                        </div>
                        <p class="tech-title-css   mb-0">ReactJS</p>
                      </div>
                    </div>
                    <div className='col align-self-lg-end'>
                      <div className='card tech-card-css text-center align-items-center'>
                        <div>
                          <img className='tech-img-styling-css  ' src={html5} />
                          <FaHtml5 className='tech-icon-styling-css  ' />
                        </div>
                        <p class="tech-title-css  mb-0">HTML5</p>
                      </div>
                    </div>
                    <div className='col align-self-lg-center'>
                      <div className='card tech-card-css text-center align-items-center'>
                        <div>
                          <img className='tech-img-styling-css  ' src={css3} />
                          <SiCss3 className='tech-icon-styling-css fc-CSS3 ' />
                        </div>
                        <p class="tech-title-css  mb-0">CSS3</p>
                      </div>
                    </div>
                    <div className='col align-self-lg-end'>
                      <div className='card tech-card-css text-center align-items-center'>
                        <div>
                          <img className='tech-img-styling-css  ' src={boostrap} />
                          <FaBootstrap className='tech-icon-styling-css fc-Bootstrap' />
                        </div>
                        <p class="tech-title-css  mb-0">Bootstrap</p>
                      </div>
                    </div>
                    <div className='col align-self-lg-center'>
                      <div className='card tech-card-css text-center align-items-center'>
                        <div>
                          <img className='tech-img-styling-css  ' src={jquery} />
                          <SiJquery className='tech-icon-styling-css fc-jQuery ' />
                        </div>
                        <p class="tech-title-css  mb-0">jQuery</p>
                      </div>
                    </div>
                    <div className='col align-self-lg-end'>
                      <div className='card tech-card-css text-center align-items-center'>
                        <div>

                          <IoLogoJavascript className='tech-img-styling-css fc-Javascript ' />
                          <IoLogoJavascript className='tech-icon-styling-css fc-Javascript ' />

                        </div>
                        <p class="tech-title-css  mb-0">Javascript</p>
                      </div>
                    </div>
                  </div>
                  <div className='row row-cols-lg-6 row-cols-3 tech-row-height-css justify-content-between  '>
                    <div className='col align-self-lg-start'>
                      <div className='card tech-card-css text-center align-items-center'>
                        <div>
                          <img className='tech-img-styling-css  ' src={nodejs} />
                          <FaNodeJs className='tech-icon-styling-css fc-NodeJs' />
                        </div>
                        <p class="tech-title-css  mb-0">NodeJs</p>
                      </div>
                    </div>
                    <div className='col align-self-lg-center'>
                      <div className='card tech-card-css text-center align-items-center'>
                        <div>
                          <img className='tech-img-styling-css  ' src={flutter} />
                          <SiFlutter className='tech-icon-styling-css fc-Flutter' />
                        </div>
                        <p class="tech-title-css  mb-0">Flutter</p>
                      </div>
                    </div>
                    <div className='col align-self-lg-start'>
                      <div className='card tech-card-css text-center align-items-center'>
                        <div>
                          <img className='tech-img-styling-css  ' src={Outsystems} />
                          <img className='tech-icon-styling-css ' src={Outsystemscolor} />

                        </div>
                        <p class="tech-title-css  mb-0">Outsystems</p>
                      </div>
                    </div>
                    <div className='col align-self-lg-center'>
                      <div className='card tech-card-css text-center align-items-center'>
                        <div>
                          <img className='tech-img-styling-css  ' src={zoho} />
                          <img className='tech-icon-styling-css ' src={zohocolor} />
                        </div>
                        <p class="tech-title-css  mb-0">Zoho Creator</p>
                      </div>
                    </div>
                    <div className='col align-self-lg-start'>
                      <div className='card tech-card-css text-center align-items-center'>
                        <div>
                          <img className='tech-img-styling-css  ' src={Webflow} />
                          <SiWebflow className='tech-icon-styling-css fc-Firebase' />
                        </div>
                        <p class="tech-title-css  mb-0">Webflow</p>
                      </div>
                    </div>
                    <div className='col align-self-lg-center'>
                      <div className='card tech-card-css text-center align-items-center'>
                        <div>
                          <img className='tech-img-styling-css  ' src={Mendix} />
                          <img className='tech-icon-styling-css  ' src={Mendixcolor} />

                        </div>
                        <p class="tech-title-css  mb-0">Mendix</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="pills-tech-2" role="tabpanel" aria-labelledby="pills-tech-2-tab" tabindex="0">
                <div>
                  <div className='row row-cols-lg-6 row-cols-3 tech-row-height-css justify-content-between  '>
                    <div className='col align-self-lg-center'>
                      <div className='card tech-card-css text-center align-items-center'>
                        <div>
                          <img className='tech-img-styling-css  ' src={reactnative} />
                          <RiReactjsLine className='tech-icon-styling-css fc-react ' />
                        </div>
                        <p class="tech-title-css   mb-0">React Native</p>
                      </div>
                    </div>
                    <div className='col align-self-lg-end'>
                      <div className='card tech-card-css text-center align-items-center'>
                        <div>
                          <img className='tech-img-styling-css  ' src={AWS} />
                          <FaAws className='tech-icon-styling-css  ' />
                        </div>
                        <p class="tech-title-css  mb-0">AWS</p>
                      </div>
                    </div>
                    <div className='col align-self-lg-center'>
                      <div className='card tech-card-css text-center align-items-center'>
                        <div>
                          <img className='tech-img-styling-css  ' src={Azure} />
                          <TbBrandAzure className='tech-icon-styling-css fc-CSS3 ' />
                        </div>
                        <p class="tech-title-css  mb-0">Azure</p>
                      </div>
                    </div>
                    <div className='col align-self-lg-end'>
                      <div className='card tech-card-css text-center align-items-center'>
                        <div>
                          <img className='tech-img-styling-css  ' src={GitHub} />
                          <BsGithub className='tech-icon-styling-css fc-Bootstrap' />
                        </div>
                        <p class="tech-title-css  mb-0">GitHub</p>
                      </div>
                    </div>
                    <div className='col align-self-lg-center'>
                      <div className='card tech-card-css text-center align-items-center'>
                        <div>
                          <img className='tech-img-styling-css  ' src={java} />
                          <FaJava className='tech-icon-styling-css fc-jQuery ' />
                        </div>
                        <p class="tech-title-css  mb-0">Java</p>
                      </div>
                    </div>
                    <div className='col align-self-lg-end'>
                      <div className='card tech-card-css text-center align-items-center'>
                        <div>

                          <img className='tech-img-styling-css  ' src={mongodb} />
                          <img className='tech-icon-styling-css ' src={mongodbcolor} />

                        </div>
                        <p class="tech-title-css  mb-0">Mongodb</p>
                      </div>
                    </div>
                  </div>
                  <div className='row row-cols-lg-6 row-cols-3 tech-row-height-css justify-content-between  '>
                    <div className='col align-self-lg-start'>
                      <div className='card tech-card-css text-center align-items-center'>
                        <div>
                          <img className='tech-img-styling-css  ' src={php} />
                          <img className='tech-icon-styling-css ' src={phpcolor} />
                        </div>
                        <p class="tech-title-css  mb-0">PHP</p>
                      </div>
                    </div>
                    <div className='col align-self-lg-center'>
                      <div className='card tech-card-css text-center align-items-center'>
                        <div>
                          <img className='tech-img-styling-css  ' src={django} />
                          <img className='tech-icon-styling-css  ' src={djangocolor} />
                        </div>
                        <p class="tech-title-css  mb-0">Django</p>
                      </div>
                    </div>
                    <div className='col align-self-lg-start'>
                      <div className='card tech-card-css text-center align-items-center'>
                        <div>
                          <img className='tech-img-styling-css  ' src={angular} />
                          <FaAngular className='tech-icon-styling-css fc-Angular' />
                        </div>
                        <p class="tech-title-css  mb-0">Angular</p>
                      </div>
                    </div>
                    <div className='col align-self-lg-center'>
                      <div className='card tech-card-css text-center align-items-center'>
                        <div>
                          <img className='tech-img-styling-css  ' src={python} />
                          <FaPython className='tech-icon-styling-css fc-Python' />
                        </div>
                        <p class="tech-title-css  mb-0">Python</p>
                      </div>
                    </div>
                    <div className='col align-self-lg-start'>
                      <div className='card tech-card-css text-center align-items-center'>
                        <div>
                          <img className='tech-img-styling-css  ' src={firebase} />
                          <SiFirebase className='tech-icon-styling-css fc-Firebase' />
                        </div>
                        <p class="tech-title-css  mb-0">Firebase</p>
                      </div>
                    </div>
                    <div className='col align-self-lg-center'>
                      <div className='card tech-card-css text-center align-items-center'>
                        <div>
                          <img className='tech-img-styling-css  ' src={mysql1} />
                          <TbBrandMysql className='tech-icon-styling-css fc-Mysql ' />
                        </div>
                        <p class="tech-title-css  mb-0">Mysql</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ul class="nav nav-pills justify-content-center tabs-title-alignment-css" id="pills-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link active" id="pills-tech-1-tab" data-bs-toggle="pill" data-bs-target="#pills-tech-1" type="button" role="tab" aria-controls="pills-tech-1" aria-selected="true">
                  <TbPlayerTrackPrevFilled className='pre-nxt-icon-css' />
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="pills-tech-2-tab" data-bs-toggle="pill" data-bs-target="#pills-tech-2" type="button" role="tab" aria-controls="pills-tech-2" aria-selected="false">
                  <TbPlayerTrackNextFilled className='pre-nxt-icon-css' />
                </button>
              </li>

            </ul>
          </div>

        </div>
      </section>
      {/* =============================SECTION-3================================= */}

      < section id='industries_section' className='industries-bg-styling ' >
        <div class='container '>
          <div class='d-block d-lg-none text-center py-3 '>
            <p className='small-heading-size-css mb-0 fc-1'>Industries Services</p>
            <p className='big-heading-size-css mb-2 '>What We <span className='fc-1'>Work</span></p>
            <div className='row  justify-content-center '>
              <p className='border-bottom-css'></p>
            </div>
          </div>
          <div class='row  row-cols-lg-3 row-cols-1 justify-content-center align-items-center g-4 '>
            <div className='col'>
              <div className='row row-cols-1   justify-content-center text-center align-items-center g-4 g-lg-3'>
                <div class='col align-self-lg-start '>
                  <div class="wrapper">
                    <div class="orange-top orange-border"></div>
                    <div class="orange-left orange-border"></div>
                    <div class="white-card white-card-script">
                      <div className='d-flex flex-column industries-flex-alignment'>

                        <p class="section-3-title-css text-center ">Healthcare</p>
                        <p className='card-inner-para-css'>Leature Technologies transforms healthcare with cutting-edge
                          solutions, prioritizing innovation for streamlined
                          operations and enhanced patient care.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col align-self-lg-center dark-opacity'>
                  <img className='section-3-card-image-center-css' src={booking} alt="Design" />

                </div>
                <div class='col align-self-lg-end'>
                  <div class="wrapper">
                    <div class="orange-top orange-border"></div>
                    <div class="orange-left orange-border"></div>
                    <div class="white-card  white-card-script">
                      <div className='d-flex flex-column industries-flex-alignment'>

                        <p class="section-3-title-css text-center">Booking Platform   </p>
                        <p className='card-inner-para-css'>Ditch scheduling chaos! Leature's booking apps boost efficiency, cut no-shows, and free up your time to shine for clients.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col'>
              <div className='row row-cols-1   justify-content-center text-center align-items-center g-4 g-lg-3'>
                <div className='col align-self-lg-start dark-opacity'>
                  <img className='section-3-card-image-center-css' src={medical} alt="Design" />

                </div>
                <div class='col section-3-card-opacity-css align-self-lg-center d-none d-lg-block'>
                  <div className='section-3-card-position-css  '>

                    <p className='small-heading-size-css mb-0 fc-1'>Industries Services</p>
                    <p className='big-heading-size-css mb-2 '>What We <span className='fc-1'>Work</span></p>
                    <div className='row  justify-content-center '>
                      <p className='border-bottom-css'></p>
                    </div>
                  </div>
                </div>
                <div className='col align-self-lg-end dark-opacity'>
                  <img className='section-3-card-image-center-css' src={beauty} />

                </div>
              </div>
            </div>
            <div className='col'>
              <div className='row row-cols-1   justify-content-center text-center align-items-center g-4 g-lg-3'>
                <div class='col align-self-lg-start'>
                  <div class="wrapper">
                    <div class="orange-top orange-border"></div>
                    <div class="orange-left orange-border"></div>
                    <div class="white-card  white-card-script">
                      <div className='d-flex flex-column industries-flex-alignment'>

                        <p class="section-3-title-css text-center">Automobiles</p>
                        <p className='card-inner-para-css'> Appointments are a breeze, customers get reminders, and service history tracks itself. Now you can focus on what matters happy customers!</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col align-self-lg-center dark-opacity'>
                  <img className='section-3-card-image-center-css' src={automobile} alt="Design" />

                </div>
                <div class='col  align-self-lg-end'>
                  <div class="wrapper">
                    <div class="orange-top orange-border"></div>
                    <div class="orange-left orange-border"></div>
                    <div class="white-card  white-card-script">
                      <div className='d-flex flex-column industries-flex-alignment'>

                        <p class="section-3-title-css text-center">Commercial industry</p>
                        <p className='card-inner-para-css'> We help you automate tedious tasks, streamline operations, and free up your time to focus on what really matters. Growing your business and serving your customers in amazing ways.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section >


      {/* =============================FOOTER-SECTION================================= */}
      <Footer />


    </div >

  );
}

export default Mainbanner;
