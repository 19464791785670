import React, { useRef, useState, Fragment } from 'react';
import Navbar from '../Common/Navbar.js';
import Footer from '../Common/Footer.js';


import '../Style.css';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
} from "react-router-dom";


import $ from "jquery";


function Termsandconditions() {


    return (
        <div>
            <Navbar />
            {/* =============================Privacy-Policy-SECTION================================= */}

            <section>
                <div className='container pt-5'>
                    <div className='row  min-vh-100 justify-content-center align-items-center pt-5 '>
                        <div className='col-lg-11'>
                            <p className='big-heading-size-css py-4 text-center'>Terms & Conditions</p>
                            <p className='inner-para-styling-css'>
                                Welcome to <span className='fc-1'>Leature Technologies!</span> We're thrilled to have you on board. These Terms & Conditions (T&C) outline the ground rules for using our awesome software development services, including web development, mobile app creation, revamps, and maintenance.
                            </p>
                            <p className='inner-para-styling-css'>
                                By using our services, you agree to these T&C. We recommend taking a moment to read through them. If you have any questions, feel free to reach out to us at <span className='fc-1'>admin@leaturetech.com.</span>
                            </p>
                            <div className='row'>
                                <div className='col'>
                                    <p className='small-heading-size-css mb-0 fc-1'>
                                        Here's the gist:
                                    </p>
                                    <div>
                                        <p className='card-inner-title-css'>The Project & You :</p>
                                        <p className='border-bottom-css-2'></p>
                                        <p className='inner-para-styling-css'>
                                            We'll work closely with you to understand your needs and deliver a fantastic product. You'll provide all the necessary info (text, images, etc.) for the project.
                                        </p>
                                    </div>
                                    <div>
                                        <p className='card-inner-title-css'>Our Work & Ownership :</p>
                                        <p className='border-bottom-css-2'></p>
                                        <p className='inner-para-styling-css'>
                                            We'll use our expertise to create magic (your software!). We retain ownership of our underlying code, but the final product belongs to you.
                                        </p>
                                    </div>
                                    <div>
                                        <p className='card-inner-title-css'>Payments & Deadlines :</p>
                                        <p className='border-bottom-css-2'></p>
                                        <p className='inner-para-styling-css'>
                                            We'll agree on fees and milestones upfront. Payments are typically made in stages as we progress. We'll work diligently to meet deadlines, but sometimes unforeseen things pop up. We'll keep you informed if there's any delay.
                                        </p>
                                    </div>
                                    <div>
                                        <p className='card-inner-title-css'>Warranties & Disclaimer :</p>
                                        <p className='border-bottom-css-2'></p>
                                        <p className='inner-para-styling-css'>
                                            While we strive for perfection, software isn't always flawless. We offer a warranty period for bug fixes on our work, but we can't guarantee the software will be completely error-free (hey, nobody's perfect!).
                                        </p>
                                    </div>
                                    <div>
                                        <p className='card-inner-title-css'>Your Stuff & Privacy :</p>
                                        <p className='border-bottom-css-2'></p>
                                        <p className='inner-para-styling-css'>
                                            We'll treat any information you provide with the utmost care. We won't share it with anyone without your permission (except, of course, if required by law).
                                        </p>
                                    </div>
                                    <div>
                                        <p className='card-inner-title-css'>Termination :</p>
                                        <p className='border-bottom-css-2'></p>
                                        <p className='inner-para-styling-css'>
                                            We hope we can create a long-lasting partnership, but if things don't work out, either of us can terminate the agreement with written notice.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col'>
                                    <p className='card-inner-title-css'>Acceptable Use :</p>
                                    <p className='border-bottom-css-2'></p>

                                    <div>
                                        <p className='inner-para-styling-css'>Leature Technologies' services are intended to help you build amazing software products. To ensure a positive experience for everyone, <span className='fc-1'>we kindly ask that you refrain from using our services for :</span>
                                        </p>

                                        <p className='inner-para-styling-css'>
                                            1. Any illegal activity or purpose that violates the law.

                                        </p>
                                        <p className='inner-para-styling-css'>
                                            2. Infringing on intellectual property rights of others (like trademarks or copyrights).
                                        </p>
                                        <p className='inner-para-styling-css'>
                                            3. Developing, distributing, or using malware or harmful software.
                                        </p>
                                        <p className='inner-para-styling-css'>
                                            4. Any actions that could damage Leature Technologies' reputation or goodwill.

                                        </p>
                                    </div>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col'>
                                    <p className='card-inner-title-css'>The Legal Stuff :
                                    </p>
                                    <div>
                                        <p className='inner-para-styling-css'>
                                            These T&C are a formal agreement, but we've tried to keep it clear and concise. They govern our relationship and how we handle any disputes (hopefully, that won't be necessary!).
                                        </p>
                                        <p className='inner-para-styling-css'>
                                            We're here to help! If you have any questions about these T&C or anything else, please don't hesitate to contact us. We value your business and want to make your experience with Leature Technologies fantastic!

                                        </p>
                                        <p className='inner-para-styling-css'>
                                            We reserve the right to update these T&C from time to time. We'll always notify you of any changes.
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <Footer />
        </div >

    );
}

export default Termsandconditions;
