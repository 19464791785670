import React from 'react';

class EmailContent extends React.Component {
    render() {
        const { skills, name, totalExperience, expectedCTC } = this.props;

        // Function to handle clicking the email link
        const handleClick = () => {
            const additionalText = 'Note : Kindly please attach your resume!';
            // Check if props exist before using them
            const bodyContent = `Name: ${name || ''}\n\nSkills: ${skills || ''}\n\nTotal Experience: ${totalExperience || ''}\n\nExpected CTC: ${expectedCTC || ''}\n\n${additionalText}`;
            const mailtoLink = `mailto:career@leaturetech.com?subject=Job Application&body=${encodeURIComponent(bodyContent)}`;
            window.location.href = mailtoLink;
        };

        return (
            <a href="/" onClick={handleClick}
                className="nav-link nav-text-css">Career
            </a>
        );
    }
}

export default EmailContent;
